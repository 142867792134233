<template>
  <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
  <div v-show="!block_data.loading_block.is_loading">
    <div id="tokusho" class="container" style="margin-top: 120px;">
      <DemoViewBlock></DemoViewBlock>
      <div class="tokusho_wrap">
        <h1>「特定商取引に関する法律」に基づく表示</h1>
        <h2>販売事業者の名称及び所在地</h2>
        <p>
          事業者名　株式会社デモサイト<br>
          住所　
        </p>
        <h2>代表者氏名</h2>
        <p>代表者氏名 佐藤太郎</p>
        <h2>販売責任者氏名</h2>
        <p>販売責任者氏名　佐藤太郎</p>
        <h2>お問い合わせ先</h2>
        <p>
          お問い合わせの受付、回答はメールにて行っております。<br>
          メールアドレス　
        </p>
        <h2>販売価格</h2>
        <p>購入手続きの際、都度画面に表示いたします。</p>
        <h2>販売価格以外に発生する料金</h2>
        <p>本サービスに関するコンテンツのダウンロード及びアプリケーション動作等に必要となるインターネット接続料、通信料（パケット通信料）等はお客様の負担となります。それぞれの料金は、お客様がご利用のインターネットプロバイダー又は携帯電話会社にお問い合わせください。</p> 
        <h2>お支払い時期・方法</h2>
        <p>お客様が選択した支払方法に関する、プラットフォーム事業者の規約に基づきます。</p>
        <h2>提供時期</h2>
        <p>購入確定後、すぐにご利用いただけます。</p>
        <h2>返品等（キャンセル）について</h2>
        <p>デジタルコンテンツの特性上、購入確定後の返品・交換には応じられません。</p>
        <h2>対応機種・動作環境</h2>
        <p>本サービスが動作するAndroid端末、iOS端末のいずれかが必要です。</p>
        <h1 style="margin-top: 30px;">「資金決済に関する法律」に基づく表示</h1>
        <h2>サービス名</h2>
        <p>デモ神社おみくじ</p>
        <h2>前払式支払手段の名称</h2>
        <p>Stripe</p>
        <!-- <p>PayPayマネーライト</p> -->
        <h2>発行事業者</h2>
        <p>
          事業者名　株式会社デモサイト<br>
          住所　　　
        </p>
        <h2>支払可能金額等</h2>
        <p>支払可能金額は50万円です。残高の上限額は100万円となります。</p>
        <h2>有効期限</h2>
        <p>有効期限はありません。 </p>
        <h2>利用できる場所</h2>
        <p>「デモ神社おみくじ」サービス内でご利用いただけます。 </p>
        <h2>利用上の注意</h2>
        <p>
          ※原則、商品販売後の払い戻しはいたしません。<br>
          ただし、各サービスの提供を終了する場合は、この限りではありません。<br>
          ※システム障害時やメンテナンス等により、一時的に商品の利用ができない場合があります。<br>
          ※商品の利用条件は、アプリケーションの利用規約をご確認ください。<br>
          ※その他、利用規約が適用されますので、利用規約の内容をご確認ください。
         </p>
        <div class="btn-basic">
          <router-link :to="{ name: 'UserTopPage' }">{{ $t("CommonBack") }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show:true,
      block_data:{
          loading_block:{
              is_loading:false
          },
      },
      paymentUrl: null
    }
  },
  methods: {
  }
};
</script>

<style>
  #tokusho{background: linear-gradient(#C4E0DD,#D9ECEA);}
  .tokusho_wrap{padding: 20px;}
  .tokusho_wrap h1{line-height: 1.5rem;text-align: center;color: #02A19C;font-size: 1.1rem;margin-bottom: 20px;border: #02A19C solid 0.1px;padding: 5px;}
  .tokusho_wrap h2{color: black;border-bottom:#02A19C solid 0.2px;padding-bottom: 10px;font-weight: bold;margin-top: 40px;}
  .tokusho_wrap p{line-height: 1.5rem;}
  .tokusho_wrap ol{margin: 20px 0 0 50px;}
  .tokusho_wrap ol li {position: relative;list-style: outside decimal;padding: 0 0 0 5px;}
  .tokusho_wrap ol li:first-child{margin-top: 0;}


</style>