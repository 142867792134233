<template>
  <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
  <div v-show="!block_data.loading_block.is_loading">
    <div class="container">
      <DemoViewBlock></DemoViewBlock>
      <div class="contents-wrap">
        <div class="kazari-head"> <img src="/img/top-bg-head.png" alt="" /> </div>
        <div class="kazari-bottom"> <img src="/img/top-bg-bottom.png" alt="" /> </div> 
        <Transition appear>
          <div class="main-area" v-if="show">
            <h1><img src="/img/top-logo.png" alt="デモ神社おみくじ" /></h1>
            <div class="btn-basic"> <a @click="modal_control">{{$t("DataNameGetFortune")}}</a> </div>
            <!-- <p>
              {{$t("NoticeFortuneCreate")}}<br /> 
              {{$t("NoticeFortuneCreate2")}}<br /> 
              {{$t("NoticeFortuneCreate3")}}
            </p> -->
            <div class="link_box_1">
              <p @click="link_to('UserFormPage')">
                正常に表示されなかった場合の<br>結果の確認方法について<br>
                <span>こちらから</span>
              </p>
            </div>
            <div class="link_box_3">
              <div class="box_child" @click="link_to('UserTokushoPage')">
                <p>特定商取引法に<br>基づく表記</p>
              </div>
              <div class="box_child" style="padding-top: 7px;" @click="link_to('UserTosPage')">
                <p>ご利用規約</p>
              </div>
              <div class="box_child" @click="link_to('UserPolicyPage')">
                <p>プライバシー<br>ポリシー</p>
              </div>
            </div>
          </div>
        </Transition>
      </div>
      <!-- モーダルブロック -->
      <InfoModalBlock @onClick="link_to('UserFortuneResult')" :list_items="list_items"></InfoModalBlock>
    </div>
  </div>
</template>

<script>
import InfoModalBlock from "@/components/blocks/custom/InfoModalBlock";
export default {
  components: {
    InfoModalBlock,
  },
  data() {
    return {
      list_items:{
        modal_condition:0
      },
      show:true,
      block_data:{
        loading_block:{
            is_loading:false
        },
      },
      paymentUrl: null
    }
  },
  methods: {
    modal_control(){
      this.list_items.modal_condition=1
    },
    link_to(page_name){
      this.$router.push({name:page_name});
    }
  }
};
</script>

<style>
  .button_box button {cursor: pointer;width: 100%;border: none;text-align: center;display: inline-block;padding: 0.5em 1em;text-decoration: none;border-radius: 15px;}
  .button_box {display: block; width: 20%;margin: 30px 0; text-align: center;}
  .btn_blue {  color: white;  background: rgb(30, 187, 226);}

  /* Body */
  html, body, div, span, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  abbr, address, cite, code,
  del, dfn, em, img, ins, kbd, q, samp,
  small, strong, sub, sup, var,
  b, i,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section, summary,
  time, mark, audio, video {margin: 0;padding: 0;border: 0;outline: 0;font-size: 100%;vertical-align: baseline;background: transparent;list-style: none;}
  .clear{content:"";display:block;clear:both}
  /* Header */
  header {}
  /*footer設定*/
  footer {}
  /*標準ボタン*/
  .btn-basic{text-align: center;margin: 30px auto;}
  #app .btn-basic a{display: inline-block;padding: 10px 50px;background: #E73728;color: #fff;border-radius: 30px;text-decoration: none;font-weight: bold;}
  .btn-basic a:hover{background-color:#bbbbbb;color: #ffffff;transition: 0.5s;}
  /*main contents*/
  /*共通設定*/
  .contents-wrap{max-width: 1000px;margin: 0 auto;width: 100%;min-height: 100vh;position: relative;display: flex;align-items: center;justify-content: center;}
  .contents-wrap .kazari-head{position: absolute;top:0;left: 0;right: 0;width: 100%;z-index: 1;}
  .contents-wrap .kazari-bottom{position: absolute;bottom:0;left: 0;right: 0;width: 100%;z-index: 1;}
  .contents-wrap .kazari-head img,.contents-wrap .kazari-bottom img{width: 100%;vertical-align: middle;}
  .main-area{margin: 15px 5px;text-align: center;position: relative;z-index: 2;}
  .main-area p{color: #02A19C;}
  .main-area img{max-width: 100%;}
  .v-enter-active,
  .v-leave-active {transition: opacity 3s ease;}
  .v-enter-from,
  .v-leave-to {opacity: 0;}

  .link_box_1{width: 100%;margin: 10px auto;padding: 20px;border: #02A19C solid 0.1px;background-color: #E6F0EF;}
  .link_box_3{display: flex;}
  .link_box_3 .box_child{
    width: 32%;
    margin: 10px auto;
    border: #02A19C solid 0.1px;
    background-color: #E6F0EF;
  }
  .link_box_3 .box_child p{
    padding: 10px;
    font-size: 0.8rem;
  }
</style>