<template>
  <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
  <div v-show="!block_data.loading_block.is_loading">
    <DemoViewBlock></DemoViewBlock>
    <div v-if="block_data.fortune_data">
      <img style="width: 100%;height: 100%;position: absolute;" v-if="block_data.fortune_data.id === 25" :src="block_data.fortune_data.image">
    </div>
    <div class="movie_wrapper">
      <video 
        v-if="!showParagraph" 
        ref="videoRef"
        @ended="handleVideoEnded"
        :style="videoStyles"
        autoplay
        muted
        playsinline
        src="/movie/omikuji_2.mp4">
      </video>
    </div>
    <!-- 結果セクション -->
    <div class="container" v-if="showParagraph">
      <div class="contents-wrap">
        <div class="kazari-head">
          <img src="/img/top-bg-head.png" alt="" />
        </div>
        <div class="kazari-bottom">
          <img src="/img/top-bg-bottom.png" alt="" />
        </div>
        <div class="main-area" data-aos="fade-in" style="margin-top: 120px;">
          <div v-if="block_data.fortune_data">
            <div v-if="block_data.fortune_data.image" class="omikuji-img">
              <img class="result_img" :src="block_data.fortune_data.image" loading="lazy">
            </div>
            <div v-if="!block_data.fortune_data.image">
              <p>{{$t("NoticeFortuneResult")}}</p>
            </div>
          </div>
          <div class="btn-green">
            <router-link :to="{ name: 'UserFormPage' }">エラーが表示された方はこちら</router-link>
            <!-- <a style="color: white;" @click="reload">結果が表示されない場合、<br>こちらを押してください。</a> -->
          </div>
          <div class="btn-green">
            <router-link :to="{ name: 'UserTopPage' }">{{ $t("CommonBack") }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref,computed } from 'vue';
export default {
    data() {
      return {
        test_str:"test",
        block_data:{
          loading_block:{
              is_loading:false
          },
          fortune_data:{
            id:0,
            label:"",
            image:"",
          },
        }   
      }
    },
    setup() {
      const showParagraph = ref(false);
      const handleVideoEnded = () => {
        showParagraph.value = true;
      };
      const videoStyles = computed(() => {
        return {
          width: '100%',
          height: window.innerHeight + 'px',
          objectFit: 'cover', 
        };
      });
      return { showParagraph, handleVideoEnded, videoStyles };
    },

    created () {
      this.process()
    },
    methods: {
      async process() {
        await this.get_data()
      },
      async get_data() {
        this.block_data.loading_block.is_loading = true
        // Stripe決済
        let response = await this.$commonFunction.axios_get('user/fortune/demo/create')
        if(response){
          this.block_data.fortune_data = response.data
          console.log(response.data)
        }
        this.block_data.loading_block.is_loading = false
      },
      reload(){
        this.$router.go(0);
      }
    }
};
</script>

  
<style>
    .container h1{font-size: 2rem;color: violet;font-weight: bold;margin-bottom: 20px;}
    .container h2{font-size: 1.2rem;margin-bottom: 10px;}
    .button_box button {cursor: pointer;width: 100%;border: none;text-align: center;display: inline-block;padding: 0.5em 1em;text-decoration: none;border-radius: 15px;}
    .button_box {display: block; width: 60%;margin: 30px 0; text-align: center;}
    .btn_blue {  color: white;  background: rgb(30, 187, 226);}
    .result_img{width: 100%;}

    .btn-green{text-align: center;margin: 30px auto;}
    .btn-green a {
      display: inline-block;
      padding: 10px 50px;
      background: #01A199;
      color: #fff;
      border-radius: 30px;
      text-decoration: none;
      font-weight: bold;
    }

    .movie_wrapper{
      margin: 0 auto;
      width: 512px;
    }
</style>