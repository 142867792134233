<template>
  <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
  <div v-show="!block_data.loading_block.is_loading">
    <div id="tokusho" class="container" style="margin-top: 120px;">
      <DemoViewBlock></DemoViewBlock>
      <div class="tokusho_wrap">
        <h1>サービスについて</h1>
        <h2>注意事項</h2>
        <p>サービスの表示には通信回線が必要になります。通信ができない環境では表示が出来ません。また、通信が不安定な状態では、表示が不完全になる可能性があります。</p>
        <h2>使用許諾</h2>
        <p>アプリをご利用頂くにあたり、必ず下記のアプリサービス利用規約をお読みください。ご利用頂く場合は、使用許諾に同意頂いたものとみなします。</p>
        <h1 style="margin-top: 20px;">デモ神社おみくじ 利用規約</h1>
        <h2>目的</h2>
        <p>
          第１条<br>
          デモ神社おみくじ 利用規約（以下「本規約」という。）は、デモ神社おみくじ を通じて提供されるサービス（以下「本サービス」という。）の利用に際しての規約です。<br>
          本サービスをご利用の際には、本規約に従っていただくようお願いいたします。
        </p>
        <h2>適用範囲</h2>
        <p>
          第２条<br>
          本規約は、株式会社デモサイト（以下「当社」という。）が提供する本サービスを利用者が利用する場合に適用されます。
        </p>
        <h2>利用契約</h2>
        <p>
          第３条<br>
          本サービスのご利用をもって本規約の内容を承諾したものとみなし、当社との利用契約（以下「利用契約」という。）が成立するものとします。
        </p>
        <h2>サービスの提供条件等</h2>
        <p>
          第４条<br>
          本サービスの利用に当たって、利用者は次の条件をいずれも満たしていることを前提とします。<br>
          本サービスを利用いただくために必要となるインターネットを利用できる環境を有したスマートフォンを所有していること。なお、スマートフォン機種毎の動作検証又は動作保証はいたしません。<br>
          日本国内に居住しており、日本語を用いて本サービスの提供を受けることができること。<br>
        </p>
        <h2>サービス内容</h2>
        <p>
          第５条<br>
          当社は、お客様に対し、次の各号に掲げるサービスを提供します。<br>
          Stripeでの事前決済<br>
          <!-- PayPayマネーライトでの事前決済<br> -->
          当社のおみくじ結果<br>
          ２　当社は、本サービスに係る業務の全部又は一部を第三者に委託できるものとします。
        </p>
        <h2>利用期間及び利用料</h2>
        <p>
          第６条<br>
          本サービスの利用期間（以下「利用期間」という。）は、利用契約が成立した日（以下「利用開始日」という。）から第9条により当社が提供を停止するまでとします。
        </p>
        <h2>利用者の遵守事項</h2>
        <p>
          第7条<br>
          利用者は、利用者による本サービスの利用と本サービスを利用してなされた一切の行為に起因して、当社又は第三者に対して損害を与えた場合（利用者が本規約上の義務を履行しないことにより当社又は第三者が損害を被った場合を含みます。）、自己の責任と費用をもって損害を賠償するものとします。<br>
          ２　 利用者は、本サービスを利用して入手したプログラムに対し、逆コンパイル又は逆アセンブルを行わないものとします。また、著作権侵害防止のための技術的保護手段の施されたデータ等に対し、当該手段の回避を行わないものとします。<br>
          ３　本サービスを利用する際に要する通信費等は、利用者が負担するものとします。<br>
        </p>
        <h2>サポート</h2>
        <p>
          第８条<br>
          利用者からの本サービスの問い合わせについては、当社を窓口とします。<br>
        </p>
        <h2>サービスの利用停止</h2>
        <p>
          第９条<br>
          利用者が、本サービスを削除した場合は、本サービスの利用資格も同時に喪失するものとします。<br>
          ２　 利用者が次の各号のいずれかに該当する場合、当社は、当該利用者に対して本サービスの全部または一部の利用を停止する場合があります。<br>
          本規約に違反した場合<br>
          本サービスの運営を妨げる行為を行った場合<br>
          当社又は第三者の著作権及びその他知的財産権を侵害する行為を行った場合<br>
          当社又は第三者の財産、名誉、信用、プライバシー等を侵害する行為を行った場合<br>
          当社又は第三者に不利益若しくは損害を与える行為を行った場合<br>
          犯罪行為又は犯罪行為に結びつく行為を行った場合<br>
          法令、条例又は公序良俗に反する行為を行った場合<br>
          その他当社が不適切と判断した行為を行った場合<br>
          ３　前項各号のいずれかの行為を利用者が行ったことにより当社が損害を被った場合、当社は利用者に対し損害の賠償を請求することがあります。
        </p>
        <h2>サービスの中断・終了</h2>
        <p>
          第10条<br>
          利用者は、次の各号に定める場合に、当社が本サービスの提供を中断することがあることを了承するものとします。<br>
          天災地変、労働争議等の不可抗力により本サービスの提供ができない場合<br>
          本サービス提供用の設備の保守上又は工事上やむをえない場合<br>
          運用上又は技術上、本サービスの一時的な中断を必要とした場合<br>
          ２　当社は、利用者への事前の通知により、本サービスの提供の全部又は一部を終了する場合があることを利用者は了承するものとします。<br>
        </p>
        <h2>反社会的勢力等の排除</h2>
        <p>
          第11条<br>
          利用者は、自らが次の各号に掲げる者（以下、「反社会的勢力等」という）に該当せず今後も該当しないこと、また、反社会的勢力等との関係を持っておらず今後も持たないことを確約します。<br>
          警察庁「組織犯罪対策要綱」記載の「暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ、特殊知能暴力集団等」その他これらに準ずる者<br>
          資金や便宜を供与したり、不正の利益を図る目的で利用するなど、前号に記載する者と人的・資本的・経済的に深い関係にある者<br>
          ２　利用者は、自ら又は第三者を利用して、次の各号に記載する行為を行わないことを確約します。<br>
          詐術、暴力的行為又は脅迫的言辞を用いる行為<br>
          違法行為又は不当要求行為<br>
          業務を妨害する行為<br>
          名誉や信用等を毀損する行為<br>
          前各号に準ずる行為<br>
          ３　当社は、利用者が前各項に違反したときは、利用者に対して損害賠償義務を負うことなく、何等の催告なしに直ちに本サービスの全部又は一部の利用を停止することがあります。
        </p>
        <h2>変更</h2>
        <p>
          第12条<br>
          本規約における当社から利用者への通知は、専用ホームページでの表示により行われるものとします。
        </p>
        <h2>通知</h2>
        <p>
          第13条<br>
          本規約における（運営者名）から利用者への通知は、専用ホームページでの表示により行われるものとします。
        </p>

        <h2>準拠法・管轄裁判所</h2>
        <p>
          第14条<br>
          本規約は、日本法に準拠し解釈されるものとします。<br>
          ２　本市と利用者の間で、本サービス又は本規約に関して紛争が生じた場合には、相互が満足できる解決を図るため誠実に対応することとします。<br>
          ３　前項により解決がなされず訴訟を提起する場合、当社の本店所在地を管轄する裁判所を専属的合意管轄裁判所とします。<br>
        </p>
        <p>
          附　則<br>
          この規約は、令和5年12月1日から施行します。<br>
          
          株式会社デモサイト
        </p>
        <div class="btn-basic">
          <router-link :to="{ name: 'UserTopPage' }">{{ $t("CommonBack") }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show:true,
      block_data:{
          loading_block:{
              is_loading:false
          },
      },
      paymentUrl: null
    }
  },
  methods: {
  }
};
</script>

<style>
</style>