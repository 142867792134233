import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue3-cookies'
import { createI18n } from 'vue-i18n'
import JaSettingCommon from "./locale/ja_common.json";
import JaSettingCustom from "./locale/ja_custom.json";
import 'bootstrap/dist/css/bootstrap.min.css'
import { BootstrapVue } from 'bootstrap-vue-3'
import CommonFunctions from './CommonFunctions.js';

// 共通ブロックの読み込み
import DemoViewBlock from "@/components/blocks/default/DemoViewBlock";
import LoadingBlock from "@/components/blocks/default/LoadingBlock";
import HeaderBlock from "@/components/blocks/default/HeaderBlock";
import PageGuideBlock from "@/components/blocks/default/PageGuideBlock";
import LinkMenuBlock from "@/components/blocks/default/LinkMenuBlock";
import TabIndexBlock from "@/components/blocks/default/TabIndexBlock";
import TabContentBlock from "@/components/blocks/default/TabContentBlock";
import SettingBlock from "@/components/blocks/default/SettingBlock";
import SampleHeaderBlock from "@/components/pages/sample/SampleHeaderBlock";
import AdminHeaderBlock from "@/components/pages/admin/AdminHeaderBlock";
import UserHeaderBlock from "@/components/pages/user/UserHeaderBlock";

// 多言語対応設定
const JaSetting = Object.assign(JaSettingCommon, JaSettingCustom);
const i18n = createI18n({
    locale: "ja",
    messages: {
        ja: JaSetting,
    },
  })
const app = createApp(App)

// コンポーネント設定
app.component('LoadingBlock', LoadingBlock)
app.component('HeaderBlock', HeaderBlock)
app.component('SampleHeaderBlock', SampleHeaderBlock)
app.component('AdminHeaderBlock', AdminHeaderBlock)
app.component('UserHeaderBlock', UserHeaderBlock)
app.component('PageGuideBlock', PageGuideBlock)
app.component('LinkMenuBlock', LinkMenuBlock)
app.component('TabIndexBlock', TabIndexBlock)
app.component('TabContentBlock', TabContentBlock)
app.component('SettingBlock', SettingBlock)
app.component('DemoViewBlock', DemoViewBlock)

// プラグイン設定
app.use(router)
app.use(VueCookies)
app.use(i18n)
app.use(BootstrapVue)

app.config.globalProperties.$commonFunction = CommonFunctions;


app.mount('#app')
