<template>
  <section id="return_button_block">
    <div class="button_box" v-if="block_data.return_block.return_page_name">
      <button class="return_button" @click="return_to(block_data.return_block.return_page_name)">
        {{ $t("CommonBack") }}
      </button>
    </div>
    <div class="breadcrumbs">
      <span v-for="(data,index) in block_data.return_block.page_guides" :key="'breadcrumb_' + index" >
        <router-link 
          :to="{ name: data.link}" 
          v-if="!data.is_query" 
        >{{ $t(data.name) }}</router-link>　
        <router-link 
          :to="{ name: data.link, query:block_data.query_parm }" 
          v-if="data.is_query" 
        >{{ $t(data.name) }}</router-link>
      </span>
    </div>
  </section>
</template>

<script>
export default {
  props: ["block_data"],
  // data() {
  // },
  methods: {
    return_to(link){
      if(this.block_data.return_block.return_page_is_query){
        this.$router.push({name:link,query:this.block_data.query_parm})
      } else {
        this.$router.push({name:link})
      }
    }
  },
};
</script>

<style>
  #return_button_block {
      display: flex;
      justify-content: flex-start;
      margin: 20px auto;
  }
  #return_button_block .button_box .return_button {
      width: 100%;
      border-radius: 10px;
      padding: 10px 15px;
      background: #fff;
      font-size: 1.3rem;
      margin-right: 10px;
      border: 0.2rem solid #2B68BA;
      color: #2B68BA;
  }
  #return_button_block .breadcrumbs{
      margin: 15px;
      font-size: 1.5rem;
  }
  #return_button_block .breadcrumbs a{
      color: #1a0dab;
  }
  #return_button_block .breadcrumbs span:not(:last-of-type):after {
      content: " / ";
  }
  #return_button_block .button_box {
      width: 15%;
      text-align: center;
  }
  @media (max-width: 800px){
      #return_button_block {
          display: block;
      }
      #return_button_block .button_box {
          width: 40%;
      }
  }
</style>