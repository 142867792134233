import { createRouter, createWebHistory } from 'vue-router'
import UserTopPage from '@/components/pages/user/UserTopPage.vue'
import UserFortuneResult from '@/components/pages/user/UserFortuneResult.vue'
import UserTokushoPage from '@/components/pages/user/UserTokushoPage.vue'
import UserPolicyPage from '@/components/pages/user/UserPolicyPage.vue'
import UserTosPage from '@/components/pages/user/UserTosPage.vue'
import UserFormPage from '@/components/pages/user/UserFormPage.vue'
import ServerErrorPage from '@/components/pages/ServerErrorPage.vue'

const routes = [
  {
    path: '/',
    name: 'UserTopPage',
    component: UserTopPage
  },
  {
    path: '/error',
    name: 'ServerErrorPage',
    component: ServerErrorPage,
  },
  {
    path: '/fortune/result',
    name: 'UserFortuneResult',
    component: UserFortuneResult
  },
  {
    path: '/tokusho',
    name: 'UserTokushoPage',
    component: UserTokushoPage
  },
  {
    path: '/tos',
    name: 'UserTosPage',
    component: UserTosPage
  },
  {
    path: '/policy',
    name: 'UserPolicyPage',
    component: UserPolicyPage
  },
  {
    path: '/form',
    name: 'UserFormPage',
    component: UserFormPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
