import axios from 'axios'
export default {
    // モデル名のインデックスを返す
    return_item_index(array,key){
        const index = array.findIndex(item => item.model_name == key);
        return index
    },
    // データをセレクトリストに変換
    create_select_list: function(source, symbol_target) {
        let id = 0
        let list_obj = {}
        let result = []
        for (const data of source) {
            id +=1
            list_obj = {
            id:id,
            value:data["id"],
            symbol:data[symbol_target],
            }
            result.push(list_obj)
        }
        return result
    },
    // apiのGetメソッド
    async axios_get(path){
        try {
            let url = `${process.env.VUE_APP_HOST_DOMAIN}${path}`
            let response = await axios.get(url);
            return response;
        } catch (error) {
            console.error(error);
            return false;
        }
    },  
    // apiのPostメソッド
    async axios_post(path,post_data){
        try {
            let url = `${process.env.VUE_APP_HOST_DOMAIN}${path}`
            let response = await axios.post(url,post_data);
            return response;
        } catch (error) {
            console.error(error);
            return false;
        }
    },      
}