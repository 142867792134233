<template>
    <section id="list_block">
      <!-- 新規登録ボタン -->
      <AddLinkButtonParts :list_items="list_items"></AddLinkButtonParts>
      <!-- 簡易検索 -->
      <ListSearchParts :list_items="list_items"></ListSearchParts>
      <!-- PCリスト -->
      <PCTableParts :list_items="list_items"></PCTableParts>
      <!-- ページネーション -->
      <PagenateParts :list_items="list_items"></PagenateParts>
      <!-- モーダル -->
      <div id="overlay" v-if="list_items.modal_condition != 0">
        <AddModalParts :list_items="list_items"></AddModalParts>
        <SelectModalParts :list_items="list_items"></SelectModalParts>
      </div>
    </section>
</template>

<script>
import AddLinkButtonParts from "@/components/blocks/default/AddLinkButtonParts";
import ListSearchParts from "@/components/blocks/default/ListSearchParts";
import PCTableParts from "@/components/blocks/default/PCTableParts";
import PagenateParts from "@/components/blocks/default/PagenateParts";
import AddModalParts from "@/components/blocks/default/AddModalParts";
import SelectModalParts from "@/components/blocks/default/SelectModalParts";
export default {
  props: ["list_items"],
  components: {
    AddLinkButtonParts,
    ListSearchParts,
    PCTableParts,
    PagenateParts,
    AddModalParts,
    SelectModalParts,
  },
  data() {
    return {
    }
  },
};
</script>

<style>
  #list_block {background: white;padding: 20px;border-radius: 8px;}
  #search_block_list .search_button {display: flex;width: 100%;margin: 20px 0;justify-content: right;}
  #search_block_list input {height: 35px;width: 30%;margin-top: 18px;}
  #search_block_list p {font-weight: bold;height: 35px;width: 30%;margin-top: 23px;margin-right: 20px;text-align: end;font-size: 1.5rem;}
  .btn_search {color: white;background: #2B68BA;}
  .btn_search_reset {color: white;background: #9A9A9A;}
  #list_block thead tr th {text-align: center;color: #2B68BA;background-color: #EDEDED;}
  #list_block .button_box button {margin: 0 auto;cursor: pointer;width: 90%;border: none;text-align: center;display: inline-block;padding: 0.5em 1em;text-decoration: none;border-radius: 15px;}
  .btn_cyan {color: white;background: #2B68BA;}
  #search_block_list .search_button button {width: 15%;margin: 10px;height: 50px;}
  @media (max-width: 800px){
    #search_block_list .search_button {display: block;}
    #search_block_list p {width: 100%;text-align: center;}
    #search_block_list input {width: 100%;}
    #search_block_list .search_button button {width: 100%;float: right;margin: 10px auto;}
  }
</style>