<template>
  <!-- 確認のモーダル -->
  <div id="overlay" v-if="list_items.modal_condition" class="info_modal">
    <div class="modal-content fadeIn">
      <div class="modal-headline">
        <button @click="modal_control(0)">×</button>
      </div>
      <div class="modal-main">
        <div class="content">
          <h1>ご利用前に必ずご確認ください</h1>
          <p>● 同意ボタンをクリックするとクレジットカードのお支払い画面に遷移します。</p>
          <!-- <p>● 同意ボタンをクリックするとPayPayのお支払い画面に遷移します。</p> -->
          <p>● おみくじは１回200円です。</p>
          <p style="color: red;">※ 本サイトはデモサイトのため、課金されません</p>
          <p>● 決済が完了するとおみくじ結果が表示されます。</p>
          <!-- <p>● PayPay決済が完了するとおみくじ結果が表示されます。</p> -->
          <img src="/img/info_modal.png"/>
          <p>● 返品等(キャンセル)は、デジタルコンテンツの特性上、購入確定後の返品・交換には応じられません。</p>
          <p>● 決済後おみくじ結果が表示されなかった場合、結果の確認方法についてのページから再表示をお試しください。</p>
          <p style="text-align: center;">
            <router-link target="_blank" rel="noopener noreferrer" :to="{ name: 'UserPolicyPage' }">プライバシーポリシー</router-link>
          </p>
          <p style="text-align: center;">
            <router-link target="_blank" rel="noopener noreferrer" :to="{ name: 'UserTosPage' }">利用規約</router-link>
          </p>
          <div class="button_box">
            <button @click="emit_event" class="btn_blue">利用規約に同意してくじを引く</button>
          </div>
          <div class="button_box">
            <button @click="modal_control(0)" class="btn_gray">閉じる</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["list_items"],
  data() {
    return {
      error_message:""
    };
  },
  // created() {
  // },
  methods: {
    // 親コンポーネントのメソッドを呼び出す
    emit_event(){
      this.$emit("onClick");
    },
    // 親のmodal_conditionを操作して表示を切り替える
    modal_control(condition) {
      this.list_items.modal_condition = condition
    },
  },
};
</script>

<style>
  /* モーダル */
  .info_modal .modal-content {
    position: relative;
    z-index: 2;
    padding: 0;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    max-height: 80vh;
    overflow-y: scroll;
    border-radius: 0;
    width: 90%;
    border: #00978E solid 1px;
    background-color: white;
  }
  #overlay .modal-content button {
    height: auto;
    background-color: white;
    color: #00978E;
    font-weight: bold;
    text-align: center;
    border: none;
    font-size: 2rem;
    padding: 0;
  }
  .info_modal .modal-main .content {
    display: block;
    margin: 20px auto;
    width: 90%;
    justify-content: end;
  }
  .info_modal .modal-main .content img{
    width: 100%;
  }
  .info_modal .modal-main .content p{
    margin: 20px auto;
    line-height: 1.5rem;
  }
  .info_modal .modal-main .content h1{
    font-size: 1.2rem;
    text-align: center;
    color: #00978E;
  }
  .info_modal .modal-main .content a{
    color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));
    text-decoration: underline;
  }
  #overlay .modal-content .modal-main .button_box {
    width: 100%;
  }
  #overlay .modal-content .modal-main .button_box button {
    color:white;
    background-color: #00978E;
    height: 60px;
    font-weight: bold;
    text-align: center;
    padding: 5px 15px;
    border: none;
    font-weight: unset;
    font-size: 1rem;
  }
  #overlay .modal-content .modal-main .button_box .btn_blue {
    color:white;
    background-color: #00978E
  }
  #overlay .modal-content .modal-main .button_box .btn_gray {
    background-color:#dfdede;
    color: #00978E
  }

  @media (min-width: 1200px){
    .info_modal .modal-content {
      width: 45%;
    }
  }
</style>
