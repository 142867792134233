<template>
  <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
  <div v-show="!block_data.loading_block.is_loading">
    <div id="tokusho" class="container" style="margin-top: 120px;">
      <DemoViewBlock></DemoViewBlock>
      <div class="tokusho_wrap">
        <h1>プライバシーポリシー</h1>
        <p>
          当サイトは、株式会社デモサイト(以下、当社)が管理し、当社の事業内容及びサービス(以下、本サービス)を紹介するサイトであり、本サービスをご利用するお客様(以下、利用者）と当社に適用される条件を定めています。当サイトを利用される前に次の事項を確認頂き、了解頂いた上でご利用ください。<br>
          個人情報保護方針<br>
          当社は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、個人情報の保護を推進致します。
        </p>
        <h2>個人情報の利用目的</h2>
        <p>
          本ウェブサイトでは、お客様からのお問い合わせ時に、お名前、e-mailアドレス等の個人情報をご入力いただく場合がございますが、これらの個人情報はご提供いただく際の目的以外では利用いたしません。お客様からお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。
        </p>
        <h2>個人情報の第三者への開示・提供の禁止</h2>
        <p>当社は、お客様よりお預かりした個人情報を適切に管理し、個人情報を第三者に開示いたしません。</p>
        <h2>法令、規範の遵守と見直し</h2>
        <p>当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。改定された条項は、当サイトに掲載された時点から適用されます。利用者の方へその都度ご連絡はいたしかねますので、ご利用の際には本ページの最新の内容をご参照ください。</p>
        <h2>Cookieの使用</h2>
        <p>Cookieとは、ウェブサーバーからお客様のブラウザへ送信・保持される情報です。 当社ウェブサイトのアクセス性・利便性を向上させる目的で、Google, Inc.のGoogle Analyticsを利用し、それにより生成されるCookieを通じて本アプリの閲覧状況などの情報を収集する場合があります。このデータはお客様個人を特定・追跡するものではありません。 
          多くのブラウザは、はじめからCookieが利用されるように設定されていますが、お客様はCookieの受け入れに際して警告を表示したり、拒否するようにブラウザの設定を変更することが可能です。
          本サービスに第三者企業のサービスとの連携やタグの設置などを行うことにより、第三者企業がCookie情報を取得し利用する場合があります。その場合に取得されるCookie情報は、当社のサーバーではなく、第三者企業のサーバーに蓄積され、各第三者企業のプライバシーポリシーに従って管理されます。
          お客様は本サイトを利用するにあたり、上記内容でのCookieの利用に同意したものとみなします。※Google,Inc.が収集する情報・プライバシーポリシーについては、Google社プライバシーポリシーをご確認ください。</p>
        <h2>お問い合わせ窓口</h2>
        <p>
          当社の個人情報の取扱に関するお問い合せは、当HP内の問い合わせフォームよりご連絡ください。
        </p>
        <h2>免責事項</h2>
        <p>
          当社は以下の事項につき、一切の責任を負わないものとします。<br>
          当サイトへの情報・資料の掲載には注意を払っておりますが、掲載された情報の正確性・安全性・有用性について一切を負いません。<br>
          当サイトの御利用につき生じた、何らかのトラブルや損失・損害等につきましては、理由の如何に関わらず当社は一切責任を負いません。<br>
          当サイトが紹介しているウェブサイトやソフトウェアの合法性、正確性、道徳性、最新性、適切性、著作権の許諾や有無など、その内容について一切責任を負いません。<br>
          当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切責任を負いません。<br>
          本サービスの中断・停止又は内容変更によってお客様が受ける損害については一切責任を負いません。
        </p>   
        <div class="btn-basic">
          <router-link :to="{ name: 'UserTopPage' }">{{ $t("CommonBack") }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show:true,
      block_data:{
          loading_block:{
              is_loading:false
          },
      },
      paymentUrl: null
    }
  },
  methods: {
  }
};
</script>

<style>
</style>