<template>
  <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
  <div v-show="!block_data.loading_block.is_loading">
    <div id="form" class="container" style="margin-top: 120px;">
      <DemoViewBlock></DemoViewBlock>
      <div v-if="(!block_data.fortune_data.error && !block_data.fortune_data.image)">
        <h1>
          おみくじ結果画面が<br>
          正常に表示されなかった場合の<br>
          対処方法について
        </h1>
        <p>おみくじ結果を正常に見ることができなかった場合や、もう一度おみくじ結果の画面を見たい場合の対処法をご案内します。</p>
        <!-- <h2>PayPayの決済番号を確認</h2> -->
        <h2>決済画面で入力したアドレスを用意</h2>
        <!-- <p>
          1.PayPay アプリを開いてください。<br>
          2.下部メニューバーにある［ウォレット］をタップ。<br>
          3.［取引履歴］の［詳細を見る］をタップすると、お支払いの履歴が表示されます。<br>
          4.該当の、おみくじを引いた際のお支払いを選択してください。
        </p> -->
        <!-- <img src="/img/form_2.png"/>
        <p>
          5.［決済番号］を確認してください。決済番号の右側の四角のマークを押すと番号がコピーできます。<br>
        </p> -->
        <img src="/img/form_3.png"/>
        <h2>メールアドレスをフォームより送信</h2>
        <p>
          6.デモ神社おみくじTOPの［結果を再表示］ボタンをクリックします。<br>
          7.以下の［送信フォーム］の入力欄に、コピーした決済番号をペーストします。<br>
          8.［結果を確認］ボタンをタップします。おみくじ結果画面が表示されます。<br>
        </p>
      </div>
      <div v-if="block_data.fortune_data.image" class="omikuji-img">
        <img class="result_img" :src="block_data.fortune_data.image" loading="lazy">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      order_id:"",
      block_data:{
          loading_block:{
              is_loading:false
          },
          fortune_data:{
            image:"",
            error:""
          }
      },
      paymentUrl: null
    }
  },
  methods: {
  }
};
</script>

<style>
  #app #form{padding-left: 20px;padding-right: 20px;}
  #form h1{ margin: 20px auto; padding: 10px; font-size: 1.2rem; color: white; background-color: #01A199; text-align: center;}
  #form h2{ margin: 20px auto; background-color: white; text-align: center; border: #01A199 0.1px solid; color: #01A199; padding: 10px;}
  #form .form_box{ padding: 20px; border: #01A199 2px solid; background-color: white;margin: 20px auto;}
  #form .form_box h2{border: none;margin: 5px auto;font-weight: bold;}
  #form .form_box p{font-size: 0.8rem;margin: 5px auto;text-align: center;}
  #form .form_box input{width: 100%;}
  #form img{ width: 100%; padding: 20px;}
  #form .btn-basic {margin: 10px auto;}
  #form .btn-basic a{background-color: #01A199;color: white;margin: 5px auto;}
</style>