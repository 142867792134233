<template>
    <div class="demo_view" v-if="is_demo_view" @click="disable_alert">
        <h1>! 注意 !</h1>
        <p>このアプリはデモ用のアプリです。</p>
        <p>データベースは不定期にリセットされます。</p>
    </div>
</template>

<script>
  import Cookies from 'js-cookie';
  export default {
    data() {
        return {
          is_demo_view:true,
        };
    },
    created(){
    },
    methods: {
      disable_alert(){
          this.is_demo_view = false
        }
    }
  };
  </script>
  

<style>
  .demo_view{
    width: 100%!important;
    height: 130px!important;
    top: 0!important;
    left: 0!important;
    margin: auto!important;
    text-align: center!important;
    position: fixed!important;
    background: #ffee00!important;
    z-index: 3!important;
    padding: 10px!important;
  }
  .demo_view h1{
    margin: 0!important;
    background-color: transparent!important;
    font-size: 1.5rem!important;
    color: red!important;
  }
  .demo_view p{
    font-size: 1.2rem!important;
    color: red!important;
  }
</style>