<template>
    <div class="content_wrapper thanks_page">
        <!-- レポートの保存 -->
        <div class="container" id="server_error">
            <p>サーバーエラーです。</p>
            <p>管理者へご連絡ください</p>
        </div>
    </div>  
</template>
  
<style>
    #server_error p{text-align: center;font-size: 1.5rem; margin: 0.5rem;color: crimson;}
</style>